//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReadyUploadSource from '@/components/readyUploadSource'
import Preservation from '@/components/preservation'
export default {
  components: {
    ReadyUploadSource,
    Preservation
  },
  data() {
    return {
      form: {
        id: 0,
        is_show: 1,
        name: '',
        picture: '',
        rank: '',
        parent_id: ''
      },
      disabled_type: !1,
      rules: {
        name: [{ required: true, message: '请填写分类名称', trigger: 'blur' }]
      }
    }
  },
  computed: {
    goodsClass() {
      return this.$store.getters.goodsTypeList
    }
  },
  created() {
    this.$store.dispatch('goods/getTypeList')
    if (this.$route.query.info) {
      let data = JSON.parse(this.$route.query.info)
      if (data.grade == '一级') this.disabled_type = !0
      this.form.is_show = data.is_show
      this.form.name = data.name
      this.form.rank = data.rank
      this.form.picture = data.picture
      this.form.parent_id = data.parent_id ? data.parent_id : ''
      this.form.id = data.id
    }
  },
  methods: {
    onSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.form.parent_id && !this.form.picture)
            return this.$message.warning('请添加分类图片')
          let data = {
            is_show: this.form.is_show,
            picture: this.form.picture,
            rank: this.form.rank,
            parent_id: this.form.parent_id || 0,
            name: this.form.name
          }
          if (this.form.id) data.id = this.form.id
          let url = data.id ? this.$api.goods.editType : this.$api.goods.addType
          this.$axios.post(url, data).then((res) => {
            if (res.code == 0) {
              this.$message.success(`${this.form.id ? '编辑成功' : '添加成功'}`)
              this.$router.push('/goods/GoodsType')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    getTypeImg(val) {
      this.form.picture = val.path
    }
  }
}
